import './Style/style.css';
import React, { useState, useRef } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import Grid from './Components/Grid';
import Game from './Components/Game'



let game = new Game();



function App() {

  const [currentWord, setCurrentWord] = useState('');
  const keyboardRef = useRef();

  var buttonTheme = [{
    class: "hg-red",
    buttons: " "
  },
  {
    class: "hg-green",
    buttons: " "
  },
  {
    class: "hg-orange",
    buttons: " "
  }];

  function onChange(input)  {
    setCurrentWord(input);
    console.log(input);

    game.type(input);
    
  }

  function onKeyPress(button)  {
    if(button === '{enter}')
    {
      game.submit();
      buttonTheme[0].buttons = game.redkeys;
      //buttonTheme[1].buttons = game.greenkeys;
      //buttonTheme[2].buttons = game.orangekeys;

      console.log(buttonTheme);
      console.log("green " + game.greenkeys);
      keyboardRef.current.clearInput();
      keyboardRef.current.setOptions({
        buttonTheme: buttonTheme
      });
    }
  }

  return (
    <div className="App">

      <p>Current Streak {game.streak}</p>

      <Grid game={game} />
      <input type="text" value={currentWord} maxLength="5" name="input1" id="input1" readOnly className="hide" />
       <Keyboard
        keyboardRef={(r) => (keyboardRef.current = r)}
        onChange={onChange}
        onKeyPress={onKeyPress}
        layout={{
          default: [
            "q w e r t y u i o p",
            "a s d f g h j k l",
            "{enter} z x c v b n m {bksp}",
          ]
        }}
        display={{
          '{bksp}': 'back',
          '{enter}': 'enter'
        }}
        inputName="input1"
        maxLength={{
          input1:5
        }}
      />
    </div>
  );
}

export default App;
