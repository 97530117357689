import Dictionary  from '../Dictionary';

class Game {

  #currentGuess = 0;

  #fnCall = [];
  #classesCall = [];

  #dictionary = new Dictionary();
  #currentWord = '';
  #currentInput = '';

  redkeys = " ";
  greenkeys = " ";
  orangekeys = " ";

  streak = 0;

  #initialised = false;

  constructor() {

    this.reset();
     
    this.#initialised = true;
  }

  reset() {
    this.#currentWord = this.#dictionary.getRandomWord();
    //console.log('current word ' + this.#currentWord);

    this.redkeys = " ";
    this.greenkeys = " ";
    this.orangekeys = " ";

    while(this.#currentGuess >= 0 && this.#initialised)
    {
      this.#fnCall[this.#currentGuess]('     ');
      this.#classesCall[this.#currentGuess]('wwwww');
      this.#currentGuess--;
    }
    this.#currentGuess = 0;
  }


  RegisterWord(index, fn, classes) {
    this.#fnCall.splice(index, 1, fn);
    this.#classesCall.splice(index, 1, classes);
  }

  setCharAt(str,index,chr) {
    if(index > str.length-1) return str;
    return str.substring(0,index) + chr + str.substring(index+1);
  }

  checkWord() {

    var classes = 'wwwww';
    var copy = this.#currentWord;

    // all greens first
    for(var i = 0; i < this.#currentInput.length; i++) {

      if(this.#currentInput[i] === copy[i]) {
        classes = this.setCharAt(classes, i, 'g');
        copy = this.setCharAt(copy, i, '-');

        if(this.greenkeys.indexOf(this.#currentInput[i]) === -1)
        {
          this.greenkeys += this.#currentInput[i] + " ";
        }

        if(this.orangekeys.indexOf(this.#currentInput[i]) > -1)
        {
          this.orangekeys = this.orangekeys.replace(this.#currentInput[i], '');
        }
      }
    }

    console.log("after green " + classes);
    console.log("after green " + copy);

    for(i = 0; i < this.#currentInput.length; i++) {

      console.log('index ' + i);

      // already dealt with, move on
      if(classes[i] === 'g')
      {
        continue;
      }

      let idx = copy.indexOf(this.#currentInput[i]);
      console.log(this.#currentInput[i] + ' at index ' + idx);
      if(idx > -1) {
        classes = this.setCharAt(classes, i, 'o');
        copy = this.setCharAt(copy, idx, '-');

        if(this.orangekeys.indexOf(this.#currentInput[i]) === -1)
        {
          this.orangekeys += this.#currentInput[i] + " ";
        }
      }
    }

    console.log("after oranges " + classes);
    console.log("after red " + copy);

    for(i = 0; i < this.#currentInput.length; i++) {

      if(this.greenkeys.indexOf(this.#currentInput[i]) === -1 &&
      this.orangekeys.indexOf(this.#currentInput[i]) === -1 &&
      this.redkeys.indexOf(this.#currentInput[i]) === -1)
      {
        this.redkeys += this.#currentInput[i] + " ";
      }

    }



    return classes;

  }

  submit() {
    console.log('submit');

    if(!this.#dictionary.validateWord(this.#currentInput))
    {
      this.#fnCall[this.#currentGuess]('     ');
      return;
    }

    if(this.#classesCall.length > this.#currentGuess)
    {
        let classes = this.checkWord();
        this.#classesCall[this.#currentGuess](classes);
    }
    

    //console.log('current word ' + this.#currentWord);
    //console.log('current guess ' + this.#currentInput);

    // check win condition
    if(this.#currentInput === this.#currentWord)
    {
      alert('You WIN!');
      this.streak++;
      this.reset();
    }
    // check lose condition
    else if (this.#currentGuess === this.#fnCall.length - 1)
    {
      alert('You LOSE! ('+this.#currentWord+')');
      this.streak = 0;
      this.reset();
    }
    else
    {
      this.#currentGuess++;
    }

 
    
  }


  type(word) {

    // pad it to 5 as we bind to the word array
    while(word.length < 5)
    {
      word += ' ';
    }

    this.#currentInput = word;

    if(this.#fnCall.length > this.#currentGuess)
    {
        this.#fnCall[this.#currentGuess](word);
    }


  }

}




export default Game;