import {React} from "react";
import Word
 from "./Word";
const Grid = ({game}) => {

  
    return (
        <div className="grid">
            <Word id="0" game={game}  />
            <Word id="1" game={game}  />
            <Word id="2" game={game}  />
            <Word id="3" game={game}  />
            <Word id="4" game={game}  />
            <Word id="5" game={game}  />
            <Word id="6" game={game}  />
            <Word id="7" game={game}  />
        </div>
    )

}

export default Grid;
